import React, { useContext, useState, useEffect } from 'react';
import { WIDTH_OPENED, WIDTH_CLOSED, HEIGHT_CLOSED, BUTTON_POSITION_BOTTOM } from '../constants';

interface IWidgetContext {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const WidgetContext = React.createContext<IWidgetContext>({} as IWidgetContext);

export const WidgetStateProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const message = {
      style: isOpen
        ? `width: ${WIDTH_OPENED}px; height: calc(100% - ${BUTTON_POSITION_BOTTOM}px);`
        : `width: ${WIDTH_CLOSED}px; height: ${HEIGHT_CLOSED}px`,
      okinaApp: true,
    };
    window.parent.postMessage(message, '*');
  }, [isOpen]);

  const value = { isOpen, setIsOpen };
  return <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>;
};

export function useWidgetState(): IWidgetContext {
  return useContext(WidgetContext);
}
