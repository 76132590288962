import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ClientApp from 'purecloud-client-app-sdk';
import { authenticate } from '../../utils/genesys/authenticate';
import { USER_ID, TEAM_NAME, GC_TARGET_ENV, GC_AUTH_CLIENT_ID } from '../../constants';
const platformClient = window.require('platformClient');

export const useGenesysIntegration = (shouldInitialize: boolean = true): string | null => {
  const gcTargetEnv = sessionStorage.getItem(GC_TARGET_ENV);
  const genesysAuthClientId = sessionStorage.getItem(GC_AUTH_CLIENT_ID);
  const [errorState, setErrorState] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!shouldInitialize) return;
    const initialize = async (): Promise<string | undefined> => {
      if (!gcTargetEnv) {
        return t('app_embed_context_error');
      }

      const client = platformClient.ApiClient.instance;
      let clientApp = null;

      try {
        clientApp = new ClientApp({
          pcEnvironment: gcTargetEnv,
        });
      } catch (e) {
        console.log('AUTHERROR:', e);
        return `${gcTargetEnv}: ${t('gc_embed_context_error')}`;
      }

      try {
        const authError = await authenticate(client, gcTargetEnv, genesysAuthClientId);
        if (authError) {
          return authError;
        }
        const profileData = await new platformClient.UsersApi().getUsersMe({
          expand: ['authorization'],
        });
        sessionStorage.setItem(USER_ID, profileData.id!);
        sessionStorage.setItem(TEAM_NAME, profileData.division!.name!);
      } catch (err: any) {
        console.log(err);
        return `${t('failed_auth_gc')} ${err.message}`;
      }
    };

    initialize().then(error => {
      if (error) {
        setErrorState(error);
      }
    });
  }, [shouldInitialize, gcTargetEnv, genesysAuthClientId, t]);
  return errorState;
};
