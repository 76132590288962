import i18n from 'i18next';

export const authenticate = (
  client: any,
  pcEnvironment: string,
  genesysAuthClientId: string | null,
) => {
  if (!genesysAuthClientId) {
    const defaultErr = pcEnvironment + i18n.t('gc_env_error');

    const localErr = i18n.t('gc_env_locale_error', { env: pcEnvironment });

    return pcEnvironment === 'localhost' ? localErr : defaultErr;
  }
  client.setEnvironment(pcEnvironment);
  client.setPersistSettings(true);

  const { origin, protocol, host, pathname } = window.location;
  const redirectUrl = (origin || `${protocol}//${host}`) + pathname;
  return client
    .loginImplicitGrant(genesysAuthClientId, redirectUrl, {
      success: function () {
        console.log('Logged in');
      },
      error: (err: any) => `${i18n.t('auth_fail')}  ${err.message}`,
    })
    .then((data: any) => {
      window.history.replaceState(null, '', `${pathname}?${data.state}`);
    });
};
