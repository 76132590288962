import styled from '@emotion/styled';
import { DropdownMenu } from '../Dropdown';

export const LocalesDropdownMenu = styled(DropdownMenu.Root)`
  width: 106px;
  .ant-menu .ant-menu-item-selected {
    background-color: #10006b;
    color: #fff;
    font-family:
      Atyp BL Display,
      sans-serif;
  }
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
    background-color: #10006b;
    color: #fff;
  }
`;

const LocaleButton = styled('button')`
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
  border: none;
  background-color: transparent;
  color: #10006b;
  font-size: 14px;
  line-height: 1.5;
  font-family:
    Atyp BL Display,
    sans-serif;
  text-transform: uppercase;
`;
export const LocaleDropdownButton = styled(LocaleButton)`
  width: 60px;
  &::before {
    transform-origin: bottom;
  }
  &::after {
    content: '';
    position: absolute;
    top: 8px;
    right: 6px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #10006b;
    transition: transform 0.3s ease;
  }
`;
