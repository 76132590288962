import React, { useState, useEffect } from 'react';
import { Routing } from './Routes';
import WidgetButton from './components/WidgetButton';
import { useUrlParams } from './hooks/useUrlParams';
import { useGenesysIntegration } from './hooks/genesys/useGenesysIntegration';
import { ErrorMessage } from './components/ErrorMessage';
import { PARENT } from './constants';

export const App = () => {
  useUrlParams();
  const parent = sessionStorage.getItem(PARENT);
  const shouldInitializeGenesys = parent === 'genesys';

  const genesysError = useGenesysIntegration(shouldInitializeGenesys);
  const [error, setError] = useState<string | null>(genesysError);

  useEffect(() => {
    setError(genesysError);
  }, [genesysError]);

  return (
    <>
      {error ? (
        <ErrorMessage message={error} />
      ) : (
        <>
          <Routing />
          <WidgetButton />
        </>
      )}
    </>
  );
};
