import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ButtonSkew } from '../ButtonSkew';

export const DropdownButton = styled(ButtonSkew)`
  width: 240px;
  margin-left: 10px;
  text-align: left;

  @media (max-width: 600px) {
    width: 160px;
  }

  &::before {
    transform-origin: bottom;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #10006b;
    margin-top: -2px;
    transition: transform 0.3s ease;
    pointer-events: none;
  }

  &[disabled] {
    pointer-events: none;
    color: #cfcce1;
    &:before {
      background-color: #f2f2fa;
    }
    &::after {
      content: '?';
      background-color: #cfcce1;
      border: none;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      text-align: center;
      line-height: 16px;
      top: 12px;
      color: #fff;
    }
  }
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 106px;
  background: #fff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  @media (max-width: 600px) {
    min-width: 160px;
  }

  .ant-dropdown-menu {
    padding: 4px 0;
    box-shadow: none;
    .ant-dropdown-menu-item {
      border-radius: 0;
      &:hover {
        background-color: #e2e0f0;
      }
    }
    .ant-dropdown-menu-item-selected,
    .ant-dropdown-menu-item-selected:hover {
      background-color: #10006b;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .ant-dropdown-menu-item a {
    font-weight: 400;
  }
`;

const Text = styled.div`
  div {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface IDropdownMenuNav {
  maxHeight?: number;
}

//@ts-ignore
const Menu: React.FC<IDropdownMenuNav> = ({ maxHeight = 200, children }) => {
  return { children };
};

const Footer = styled(Link, {
  shouldForwardProp: propName => propName !== 'single',
})<{ single: boolean }>`
  margin-top: ${({ single }) => (single ? '4px' : 0)};
  margin-bottom: 4px;
  padding: 0 16px;
  height: 36px;
  line-height: 36px;
  font-weight: 400;
  box-shadow: ${({ single }) => (single ? 'none' : `0 -1px 0 0 #e2e0f0`)};
  transition:
    border-color 0.3s,
    background 0.3s,
    padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    background-color: #f2f2fa;
  }
`;

export const DropdownMenu = {
  Root,
  Menu,
  Footer,
  Text,
};
