import styled from '@emotion/styled';
import { ReactComponent as LogoIcon } from '../assets/icons/callout-icon.svg';
import { ReactComponent as WhiteCross } from '../assets/icons/white-cross.svg';
import { useWidgetState } from '../state/widgetState';
import {
  BUTTON_WIDTH,
  BUTTON_POSITION_RIGHT,
  BUTTON_POSITION_BOTTOM,
  BUTTON_HEIGHT,
} from '../constants';

const WidgetButton = () => {
  const { isOpen, setIsOpen } = useWidgetState();

  return (
    <Button onClick={() => setIsOpen(!isOpen)}>
      <Cross open={isOpen} />
      <Logo open={isOpen} width={40} height={40} />
    </Button>
  );
};

type Props = {
  open: boolean;
};

const Button = styled.button`
  background: #10006b;
  border: none;
  border-radius: 50%;
  bottom: ${BUTTON_POSITION_BOTTOM}px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  width: ${BUTTON_WIDTH}px;
  height: ${BUTTON_HEIGHT}px;
  position: fixed;
  right: ${BUTTON_POSITION_RIGHT}px;
`;

const Logo = styled(LogoIcon)`
  opacity: ${(props: Props) => (props.open ? '0' : '1')};
  position: absolute;
  top: -8px;
  right: -10px;
  width: 80px;
  height: 80px;
`;
const Cross = styled(WhiteCross)`
  opacity: ${(props: Props) => (props.open ? '1' : '0')};
  transform: ${(props: Props) => (props.open ? 'rotate(-90deg)' : 'null')};
  position: ${(props: Props) => (props.open ? 'relative' : 'absolute')};
  top: ${(props: Props) => (props.open ? '0' : '20px')};
  left: ${(props: Props) => (props.open ? '0' : '22px')};
  transition:
    transform 0.5s ease 0s,
    opacity 0.5s ease 0s;
  margin-top: 4px;
`;

export default WidgetButton;
