export const CHAT_WINDOW_WIDTH = 370;

export const POSITION_RIGHT = 20;

export const POSITION_BOTTOM = 96;

export const BUTTON_WIDTH = 60;

export const BUTTON_HEIGHT = 60;

export const BUTTON_POSITION_RIGHT = 20;

export const BUTTON_POSITION_BOTTOM = 20;

export const WIDTH_OPENED = CHAT_WINDOW_WIDTH + POSITION_RIGHT;

export const WIDTH_CLOSED = BUTTON_WIDTH + BUTTON_POSITION_RIGHT;

export const HEIGHT_CLOSED = BUTTON_HEIGHT + BUTTON_POSITION_BOTTOM;

export const USER_ID = 'okina-userId';

export const TEAM_NAME = 'okina-teamId';

export const ORG_ID = 'okina-orgId';

export const PARENT = 'okina-parent';

export const GC_TARGET_ENV = 'okina-gcTargetEnv';

export const GC_AUTH_CLIENT_ID = 'okina-genesysAuthClientId';
