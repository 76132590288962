import React, { useContext, useState } from 'react';

interface IAuthContext {
  authenticated: boolean;
  signIn: () => void;
}

interface IState {
  authenticated: boolean;
}

const getInitialData = () => {
  return {
    authenticated: false,
  };
};

const AuthContext = React.createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [state, setState] = useState<IState>(getInitialData());

  const signIn = () => {
    setState({
      authenticated: true,
    });
  };

  const value = { ...state, signIn };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth(): IAuthContext {
  return useContext(AuthContext);
}
