import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ORG_ID,
  USER_ID,
  PARENT,
  GC_TARGET_ENV,
  GC_AUTH_CLIENT_ID,
  TEAM_NAME,
} from '../../constants';
import { getHeader } from '../../utils/getHeader';

export const useUrlParams = () => {
  const { search } = useLocation();
  const { i18n } = useTranslation();

  const genesysAuthClientId = getHeader(search, 'genesysAuthClientId');
  const userId = getHeader(search, 'user-id');
  const gcTargetEnv = getHeader(search, 'gcTargetEnv');
  const parent = getHeader(search, 'parent');
  const orgId = getHeader(search, 'orgId');
  const teamName = getHeader(search, 'team-name');
  const langTag = getHeader(search, 'langTag');

  genesysAuthClientId && sessionStorage.setItem(GC_AUTH_CLIENT_ID, genesysAuthClientId);
  userId && sessionStorage.setItem(USER_ID, userId);
  gcTargetEnv && sessionStorage.setItem(GC_TARGET_ENV, gcTargetEnv);
  parent && sessionStorage.setItem(PARENT, parent);
  orgId && sessionStorage.setItem(ORG_ID, orgId);
  teamName && sessionStorage.setItem(TEAM_NAME, teamName);

  useEffect(() => {
    if (langTag && i18n.language !== langTag) {
      i18n.changeLanguage(langTag);
    }
  }, [langTag, i18n]);
};
