export const ErrorMessage = ({ message }: { message: string }) => {
  const errorStyle = {
    color: 'red',
    border: '1px solid red',
    padding: '10px',
    margin: '10px 0',
    backgroundColor: '#ffe6e6',
    borderRadius: '5px',
  };

  return <div style={errorStyle}>{message}</div>;
};
