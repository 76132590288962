import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useAuth } from './../state/auth';
import { ReactComponent as LogoIcon } from '../assets/icons/logo-wellbeing.svg';
import { useWidgetState } from '../state/widgetState';
import {
  CHAT_WINDOW_WIDTH,
  POSITION_RIGHT,
  POSITION_BOTTOM,
  BUTTON_POSITION_BOTTOM,
} from '../constants';
import { SelectLocal } from '../components/SelectLocal';

const LandingPage = () => {
  const auth = useAuth();
  const { t } = useTranslation();

  const onSubmit = () => {
    auth.signIn();
  };
  const { isOpen } = useWidgetState();

  return (
    <Main isOpen={isOpen}>
      <Wrapper isOpen={isOpen}>
        <SelectLocal />
        <Container>
          <LogoIcon />
        </Container>
        <StartButton onClick={onSubmit}>{t('start')}</StartButton>
        <Text>{t('powered_by_sabio')}</Text>
      </Wrapper>
    </Main>
  );
};
export default LandingPage;

type Props = {
  isOpen: boolean;
};

const Main = styled.div`
  height: calc(97% - ${POSITION_BOTTOM}px);
  position: fixed;
  right: ${POSITION_RIGHT}px;
  bottom: ${POSITION_BOTTOM}px;
  width: ${CHAT_WINDOW_WIDTH}px;
  overflow: ${(props: Props) => (props.isOpen ? 'revert' : 'hidden')};
  @media (max-width: 500px) {
    width: 100%;
    right: 0px;
  }
`;
const Wrapper = styled.div`
  opacity: ${(props: Props) => (props.isOpen ? '1' : '0')};
  height: ${(props: Props) => (props.isOpen ? `calc(100% - ${BUTTON_POSITION_BOTTOM}px)` : '0')};
  transform: ${(props: Props) =>
    props.isOpen
      ? 'translate3d(0px, 0px, 0px) scale(1, 1)'
      : 'translateX(25%) translateY(55%) scale(0.5, 0.5)'};
  transition: ${(props: Props) =>
    props.isOpen
      ? 'transform 0.2s ease 0s, opacity 0.2s ease-in 0s'
      : 'transform 0.2s ease, opacity 0.2s ease-in, height 0s ease 0.2s'};
  box-shadow: rgb(0 0 0 / 24%) 1px 4px 15px 0px;
  background-image: linear-gradient(180deg, #9fc6f6 0%, #10006b 100%);
  border-radius: 4px;
  padding: 10px;
`;

const Container = styled.div`
  text-align: center;
  margin-top: 50%;
`;

const StartButton = styled.button`
  position: absolute;
  bottom: 80px;
  left: 14px;
  color: white;
  width: 343px;
  height: 56px;
  background: #10006b;
  border-radius: 28px;
  font-size: 16px;
  line-height: 24px;
  border: none;
  cursor: pointer;
  font-family: Rubik;
  @media (max-width: 500px) {
    width: 90%;
  }
`;

const Text = styled.div`
  position: absolute;
  bottom: 35px;
  color: white;
  width: 100%;
  text-align: center;
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
`;
