import { Route, Routes } from 'react-router-dom';
import { useAuth } from './state/auth';
import LandingPage from './pages/LandingPage';
import DfMessenger from './pages/DfMessenger';

export const Routing = (): JSX.Element => {
  const BASE_URL = '/*';
  const { authenticated } = useAuth();

  return !authenticated ? (
    <Routes>
      <Route path={BASE_URL} element={<LandingPage />} />
    </Routes>
  ) : (
    <Routes>
      <Route path={BASE_URL} element={<DfMessenger />} />
    </Routes>
  );
};
