import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './state/auth';
import { WidgetStateProvider } from './state/widgetState';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './locales/i18n';

const root = ReactDOM.createRoot(document.getElementById('okina') as HTMLElement);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <WidgetStateProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </WidgetStateProvider>
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
